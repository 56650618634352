import type { MaybeRef, Ref } from 'vue'
import type { RentReviewTable, RentReviewType } from '../types'
import { convertToRentReviewTable } from '../utils'

export const useRentReviewData = (
  initialValue: MaybeRef<RentReviewTable[]>,
  rentReviewType: Ref<RentReviewType>,
) => {
  const dayjs = useDayjs()

  const {
    items,
    filteredItems: _filteredItems,
    add: _add,
    update,
    remove,
    getById,
  } = useRepeater<RentReviewTable>(initialValue)

  const filteredItems = computed(() =>
    _filteredItems.value.map(convertToRentReviewTable),
  )

  const add = (item: RentReviewTable) => {
    if (filteredItems.value.length > 0) {
      return _add(item)
    }

    // Generate
    const startDate = dayjs(item.Date)
    const expiryDate = dayjs(rentReviewType.value.LeaseEndDate)

    const startYear = startDate.year()
    const endYear = expiryDate.year()

    for (let year = startYear + 1; year < endYear; year++) {
      const date = startDate.set('year', year).toISOString()

      const newItem = (
        item.__duplicate || year === startYear + 1
          ? { ...item, Date: date }
          : { Date: date }
      ) as RentReviewTable

      _add(newItem)
    }
  }

  const isEmpty = computed(() => filteredItems.value.length === 0)

  return {
    items,
    filteredItems,
    add,
    update,
    remove,
    getById,
    isEmpty,
  }
}
